<template>
  <div
    class="service-grid-slider-area section-space--inner--60"
    style="background-color:#dfdee3;"
    id="header-top"
  >
    <div class="banner-area-9">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="row banner-wrap">
              <div class="col-lg-4 pt-md-30">
                <div class="banner-item">
                  <div class="banner-img">
                    <router-link to="/project-details">
                      <img src="/assets/img/service/servicio_mall1.jpg" alt="Quicky's Banner" />
                    </router-link>
                    <!--<router-link to="/" class="btn ht-btn--round">CONTACT US</router-link>-->
                  </div>
                </div>
              </div>
              <div class="col-lg-4 pt-md-30">
                <div class="banner-item">
                  <div class="banner-img">
                    <a href="javascript:void(0)">
                      <img src="/assets/img/service/servicio_pay1.jpg" alt="Quicky's Banner" />
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 pt-md-30">
                <div class="banner-item">
                  <div class="banner-img">
                    <a href="javascript:void(0)">
                      <img src="/assets/img/service/servicio_williU1.jpg" alt="Quicky's Banner" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="row banner-wrap">
              <div class="col-lg-4 pt-md-30">
                <div class="banner-item">
                  <div class="banner-img">
                    <a href="javascript:void(0)">
                      <img src="/assets/img/service/servicio_likeUgo1.jpg" alt="Quicky's Banner" />
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 pt-md-30">
                <div class="banner-item">
                  <div class="banner-img">
                    <a href="javascript:void(0)">
                      <img src="/assets/img/service/servicio_lili1.jpg" alt="Quicky's Banner" />
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 pt-md-30">
                <div class="banner-item">
                  <div class="banner-img">
                    <a href="javascript:void(0)">
                      <img src="/assets/img/service/servicio_ukonga1.jpg" alt="Quicky's Banner" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="row banner-wrap">
              <div class="col-lg-4 pt-md-30">
                <div class="banner-item">
                  <div class="banner-img">
                    <a href="javascript:void(0)">
                      <img src="/assets/img/service/servicio_euro1.jpg" alt="Quicky's Banner" />
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 pt-md-30">
                <div class="banner-item">
                  <div class="banner-img">
                    <a href="javascript:void(0)">
                      <img src="/assets/img/service/servicio_b2b1.jpg" alt="Quicky's Banner" />
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 pt-md-30">
                <div class="banner-item">
                  <div class="banner-img">
                    <a href="javascript:void(0)">
                      <img src="/assets/img/service/servicio_colon1.jpg" alt="Quicky's Banner" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServicesGrid"
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
</style>